<template>
  <a-spin tip="Processing..." :spinning="spinning" >

    <CAlert v-if="errorMsgs.length > 0" color="danger">
      <strong>Error: </strong>
      <ul v-for="msg in errorMsgs" :key="msg">
        <li>{{msg}}</li>
      </ul>
    </CAlert>

    <CCardBody class="p-0">
        <div class="row mb-2">
            <div class="col">
                Action Date Filter
            </div>
        </div>
        <div class="row">
            <div class="col">
                <a-date-picker :format="dateFormat" @change="dateChange" style="width: 100%" />
            </div>
        </div>

        <div class="mt-2">
            <CButton 
            color='primary'
            :block="true"
            class="btn-sm"
            @click="filter"
            >
                Search
            </CButton>
        </div>


        <div class="row mt-2">
            <div class="col">
                <a-collapse @change="collapsePanelChange" accordion>
                    <a-collapse-panel :key="r" v-for="r in batchRecords" :header="'ACTION#:' + r">
                        <h6 v-for="r in records" :key="r.id"> {{r.description}} </h6> 
                    </a-collapse-panel>
                </a-collapse>
            </div>
        </div>

    </CCardBody>
   
  </a-spin>
</template>
<script>  
  import encryptStorage from "../../controllers/encryptStorage.js";
  import HTTPRequest from "../../controllers/httpRequest.js";
  import { mapState } from "vuex";

  export default {
    name: "MassUpdateHistory",
    components: { },
    computed: {
      ...mapState(["endPoint"])
    },
    data() {
      return {
        errorMsgs: [],
        authHeaders: {
          Authorization: 'Bearer ' + encryptStorage.getItem("accessToken"),
          "Access-Control-Allow-Origin": "*" 
        },
        spinning: false,
        dateFormat: "DD-MMM-YYYY",
        filterDate: "",
        records: [],
        batchRecords: []
      }
    },
    methods: {
        dateChange(date, dateString) {
            this.filterDate = dateString;
        },
        async filter(){
            var response = await HTTPRequest.getRequest(this.endPoint, "/api/v1/action_histories/get_by_date?action=MassUpdateBookings&date="+this.filterDate, null);
            this.batchRecords = response.action_histories_batch
        },
        async collapsePanelChange(keys){
            var response = await HTTPRequest.getRequest(this.endPoint, "/api/v1/action_histories/get_by_batch?extra="+keys+"&action=MassUpdateBookings&date="+this.filterDate, null);
            this.records = response.action_histories
        },
    }
  };
</script>


<style scoped>
</style>