<template>
  <div :key="componentKey">

      <a-drawer
      placement="right"
      :visible="massUpdateDrawerVisibile"
      @close="closeDrawer"
      :zIndex=1050
      :width=1200
    > 
        <template slot='title'>
          Mass Update Bookings

          <a-dropdown>
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
              <CIcon name="cil-options"/>
                </a>
                <a-menu slot="overlay">
                  <a-menu-item @click="openDrawer('massUpdateBookingsHistory')">
                      <a>Mass Update Bookings History</a>
                  </a-menu-item>                      
                </a-menu>
            </a-dropdown>
        </template>   
        <mass-update-bookings :key="massUpdateKey" @sendToBookings="receivedFromMassUpdate"></mass-update-bookings>
    </a-drawer>

    <a-drawer
      title="Mass Update History"
      width="720"
      :zIndex=1050
      :closable="false"
      :visible="massUpdateHistoryDrawer"
      @close="closeDrawer"
    >
      <mass-update-history :key="massUpdateHistoryKey"></mass-update-history>  
    </a-drawer>

    <CCard>
      <CCardHeader>
        <slot name="header">
          <CIcon name="cil-book"/>  {{ $options.name }} 
          <div class="card-header-actions">

            <div class="row">
              <CButton
                size="sm"
                :color="showAdvancedFilters ? 'danger' : 'warning'"
                variant="ghost"
                @click="toggleAdvancedFilters"
              > {{ showAdvancedFilters ? 'Close Advanced Filters' : 'Advanced Filters' }}
              </CButton>

              <div class="col-auto">
                <router-link :to="{ name: 'BookingForm', params: { id: -1 } }">
                  <CButton
                    size="sm"
                    color="success"
                  > + Add New Booking
                  </CButton>
                </router-link>
              </div>

              <div class="col-auto">
                  <a-dropdown>
                      <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                          <CIcon name="cil-options"/>
                      </a>
                      <a-menu slot="overlay">
                          <a-menu-item @click="openDrawer('massUpdateBookings')">
                              <a>Mass Update Bookings</a>
                          </a-menu-item>                      
                      </a-menu>
                  </a-dropdown>
              </div>
            </div>
          </div>
        </slot>
      </CCardHeader>
      <CCardBody>
        
        <CCard v-if="showAdvancedFilters">
          <CCardHeader>
            Advanced Filters
              <div class="card-header-actions">
                <CButton
                  size="sm"
                  color="danger"
                  variant="ghost"
                  @click="clearFilters"
                  class="mr-2"
                > Reset Filters
                </CButton>
                <a-switch v-if="(loggedInUserRole == 'admin')" @change="switchFilterChange" checked-children="All Records" un-checked-children="Owned" :checked="isAllOwned" class="mr-2"/>
              </div>
          </CCardHeader>
          <CCardBody>
            
            <div class="row">
              <div class="mt-1 col-auto"> Column Filter: </div>
              <div class="col">
                <a-input-group compact>
                  <a-select v-model="selectedFilter">
                    <a-select-option v-for="f in filterTypes" :key="f.key" :value="f.field">
                      {{ f.title }}
                    </a-select-option>
                  </a-select>
                  <a-input style="width: 20%" class='mr-2' @pressEnter="inputFilter" v-model="inputFilterData"/>
                </a-input-group>
              </div>
            </div>
            <div class="row mt-1">
              <div class="mt-1 col-auto"> Enquiry Date Range: </div>
              <div class="col"><a-range-picker @change="onChangeDateRangeFilter" /></div>
            </div>
          </CCardBody>
        </CCard>
        <VueTabulator ref="tabulator" :options="tabulatorOptions"/>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
  import encryptStorage from "../../controllers/encryptStorage.js";
  import { mapState } from "vuex";
  import moment from 'moment';
  import _startCase from 'lodash/startCase';
  import MassUpdateBookings from "../../views/utils/MassUpdateBookings.vue";
  import MassUpdateHistory from "../../views/utils/MassUpdateHistory.vue";

  export default {
    name: "Bookings",
    components: { MassUpdateBookings, MassUpdateHistory },
    computed: {
      ...mapState(["endPoint"])
    },
    mounted() {
      this.tabulatorInstance = this.$refs.tabulator.getInstance();
      if (this.$route.params.type=='expiring_provisional') {
        this.tabulatorInstance.setFilter(this.expiringProvisionalFilter);
      } else {
        this.tabulatorInstance.setFilter('status', "like", _startCase(this.$route.params.type));
      }

    },
    data() {
      return {
        massUpdateHistoryKey: 0,
        massUpdateHistoryDrawer: false,
        massUpdateKey: 0,
        massUpdateDrawerVisibile: false,
        showAdvancedFilters: false,
        isAllOwned: true,
        inputFilterData: "",
        selectedFilter: 'booked_by_name',
        filterTypes: [
          { field: 'booked_by_name', title: 'Booked By' },
          { field: 'client_name', title: 'Client Name' },
          { field: 'course_title', title: 'Course Title' },
          { field: 'created_at', title: 'Enquiry Date' },
          { field: 'expiry_date', title: 'Expiry Date' },
          { field: 'status', title: 'Status' },
          { field: 'po_number', title: 'PO#' },
          { field: 'venue', title: 'Venue' }
        ],
        tabulatorInstance: null,
        loggedInUserRole: encryptStorage.getItem('loggedInUserRole'),
        loggedInUserId: encryptStorage.getItem('loggedInUserId'),
        authHeaders: {
          Authorization: 'Bearer ' + encryptStorage.getItem("accessToken"),
          "Access-Control-Allow-Origin": "*" 
        },
        componentKey: 0,
        tabulatorOptions: {
          placeholder:"Loading..",
          ajaxURL: this.$store.state.endPoint+"/api/v1/bookings/get_all_draft_event_bookings",
          ajaxConfig:{
                  method:"GET",
                  headers: {
                    "Authorization" : 'Bearer ' + encryptStorage.getItem("accessToken"),
                    "Access-Control-Allow-Origin": "*"
                  },
              },
          ajaxProgressiveLoad: "scroll",
          height:"70vh",
          layout:"fitColumns",
          rowClick: this.tabulatorRowClick,
          movableColumns: true,
          columns: [
            { field: 'id', title: 'ID' },
            { field: 'po_number', title: 'PO#' },
            { field: 'status', title: 'Status', formatter: this.statusFormatter},
            { field: 'booked_by_name', title: 'Booked By' },
            { field: 'client_name', title: 'Client Name' },
            { field: 'tel', title: 'Telephone' },
            { field: 'venue', title: 'Venue' },
            { field: 'company_name', title: 'Company Name' },
            { field: 'course_title', title: 'Course Title (AM/PM)' },
            { field: 'preferred_dates', title: 'Preferred Dates' },
            { field: 'availability_and_trainer_names', title: 'Trainer Schedule' },
            { field: 'enquiry_date', title: 'Enquiry Date', headerSort: false },
            { field: 'expiry_date', title: 'Expiry Date', headerSort: false, formatter: this.expiryDateFormatter},
            { field: 'booked_by_id', visible: false},
            { field: 'created_at_epoch', visible: false},
            { field: 'expiry_date_epoch', visible: false},
          ]
        },
      };
    }, 
    methods: {
      receivedFromMassUpdate(){
        this.closeDrawer();
        this.showSuccessToaster = true;
      },
      closeDrawer(){
        this.massUpdateDrawerVisibile = false;
        this.massUpdateKey++;
        this.massUpdateHistoryDrawer = false;
        this.massUpdateHistoryKey++;
      },
      openDrawer(type){
        if (type == 'massUpdateBookings') {
          this.massUpdateDrawerVisibile = true;
          this.massUpdateKey++;
        } else if( type == 'massUpdateBookingsHistory') {
          this.massUpdateDrawerVisibile = false;
          this.massUpdateKey++;
          this.massUpdateHistoryDrawer = true;
          this.massUpdateHistoryKey++;
        }
      },
      moment,
      clearFilters(){
        this.tabulatorInstance.clearFilter();
        if (!this.isAllOwned) this.tabulatorInstance.addFilter("booked_by_id", "=", this.loggedInUserId);
      },
      statusFormatter(cell){
        let value = cell.getValue();

        switch(value) {
          case 'For Approval':
            return "<span style='color:#0D86FF; font-weight:bold;'>" + value + "</span>";
          case 'Provisional Booking':
            return "<span style='color:#535D6D; font-weight:bold;'>" + value + "</span>";
          case 'Booked':
            return "<span style='color:#26994D; font-weight:bold;'>" + value + "</span>";
          case 'Rejected':
            return "<span style='color:#E29C06; font-weight:bold;'>" + value + "</span>";
          case 'Cancelled':
            return "<span style='color:#E03232; font-weight:bold;'>" + value + "</span>";
          case 'Draft':
            return "<span style='color:#535D6D; font-weight:bold;'>" + value + "</span>";
        }
        return value;
      },
      expiringProvisionalFilter(data){        
        let today_date = new Date();

        let expiry_date_epoch = String(data.expiry_date_epoch).substring(0, 10);
        let epoch_today = String(today_date.setDate(today_date.getDate()).valueOf()).substring(0, 10);
        let epoch_date_7 = String(today_date.setDate(today_date.getDate() + 7).valueOf()).substring(0, 10);

        if(expiry_date_epoch > epoch_today  && expiry_date_epoch < epoch_date_7){
            return true;
        }else{
            return false;
        }
      },
      expiryDateFormatter(cell){
        let value = cell.getValue();
        let today_date = new Date();

        let expiry_date_epoch = String(cell.getRow().getCell('expiry_date_epoch').getValue()).substring(0, 10);
        let epoch_today = String(today_date.setDate(today_date.getDate()).valueOf()).substring(0, 10);
        let epoch_date_7 = String(today_date.setDate(today_date.getDate() + 7).valueOf()).substring(0, 10);

        if(expiry_date_epoch > epoch_today  && expiry_date_epoch < epoch_date_7){
            return "<span style='color:red; font-weight:bold;'>" + value + "</span>";
        }else{
            return value;
        }
      },
      onChangeDateRangeFilter(date) {
        let prevDateFilter = this.tabulatorInstance.getFilters().filter(f => f.field == 'created_at_epoch');
        for (let i in prevDateFilter) {
          this.tabulatorInstance.removeFilter(prevDateFilter[i].field, prevDateFilter[i].type, prevDateFilter[i].value);
        }

        if (date.length > 0) {
          let from = date[0].unix();
          let to = date[1].unix();

          this.tabulatorInstance.addFilter("created_at_epoch", ">", from);
          this.tabulatorInstance.addFilter("created_at_epoch", "<", to);
        }

      },
      toggleAdvancedFilters(){
        this.showAdvancedFilters = !this.showAdvancedFilters;
      },
      tabulatorRowClick(e,row) {
        this.$router.push({ name: 'BookingForm', params: { id: row._row.data.id } })
      },
      switchFilterChange(state) {
        this.isAllOwned = state;
        if (state==true) {
          this.tabulatorInstance.removeFilter("booked_by_id", "=", this.loggedInUserId);
        } else {
          this.tabulatorInstance.addFilter("booked_by_id", "=", this.loggedInUserId);
        }
      },
      inputFilter(e){
        this.tabulatorInstance.setFilter(this.selectedFilter, "like", this.inputFilterData);
        if (!this.isAllOwned) this.tabulatorInstance.addFilter("booked_by_id", "=", this.loggedInUserId);
      }
    }
  };
  
</script>
<style lang='scss'>
  @import "~vue-tabulator/dist/scss/bootstrap/tabulator_bootstrap4";
</style>
