<template>
  <a-spin tip="Processing..." :spinning="spinning" >

    <CAlert v-if="errorMsgs.length > 0" color="danger">
      <strong>Error: </strong>
      <ul v-for="msg in errorMsgs" :key="msg">
        <li>{{msg}}</li>
      </ul>
    </CAlert>

    <div >
      <CButton 
        color='primary'
        :block="true"
        class="btn-sm"
        @click="massUpdate"
      >
        Mass Update
      </CButton>
    </div>

    <CCardBody class="p-0 mt-2">
        <div class="row mt-4 mb-2">
            <div class="col">
                Booking Date Filter
            </div>
        </div>
        <div class="row">
            <div class="col">
                <a-range-picker :format="dateFormat" @change="dateChange" style="width: 100%"/>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col">
            <autocomplete
                ref="autocomplete1"
                placeholder="Search Clients"
                :source="findClientsEndpoint"
                input-class="form-control"
                results-property="data"
                :results-display="autocompleteFormatDisplayClient"
                :request-headers="authHeaders"
                @selected="selectClient"
            >
            </autocomplete>
            <CListGroup class="mt-2" v-if="selectedClients.length > 0">
                <CListGroupItem class="p-2" color="dark">
                <strong>Selected Clients(s)</strong>
                </CListGroupItem>
                <CListGroupItem class="p-1" v-for="d in selectedClients" :key="d.id">
                {{ d.name + " " + d.surname }}
                <a @click="removeClient(d.id)">
                    <CIcon class="float-right mr-2" style="cursor: pointer;" name="cil-x"/>
                </a>
                </CListGroupItem>
            </CListGroup>
            </div>

            <div class="col">
            <autocomplete
                ref="autocomplete"
                placeholder="Search Course"
                :source="findCoursesEndpoint"
                input-class="form-control"
                results-property="data"
                :results-display="autocompleteFormatDisplayCourse"
                :request-headers="authHeaders"
                @selected="selectCourse"
            >
            </autocomplete>

            <CListGroup class="mt-2" v-if="selectedCourses.length > 0">
                <CListGroupItem class="p-2" color="dark">
                <strong>Selected Course(s)</strong>
                </CListGroupItem>
                <CListGroupItem class="p-1" v-for="d in selectedCourses" :key="d.id">
                {{ d.title }}
                <a @click="removeCourse(d.id)">
                    <CIcon class="float-right mr-2" style="cursor: pointer;" name="cil-x"/>
                </a>
                </CListGroupItem>
            </CListGroup>
            </div>
        </div>

      <hr>
      <div class="row mt-2">
        <div class="col">
          <CTextarea
              placeholder="Background of Learners"
              v-model="data.background_learners"
            >
          </CTextarea>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <CTextarea
              placeholder="Course Timings"
              v-model="data.course_timings"
            >
          </CTextarea>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <CTextarea
              placeholder="Training Equipment Required"
              v-model="data.training_equipment_required"
            >
          </CTextarea>
        </div>
      </div>      
      <div class="row mt-2">
        <div class="col">
          <CTextarea
              placeholder="Booking Notes"
              v-model="data.booking_notes"
            >
          </CTextarea>
        </div>
      </div>

    </CCardBody>
   
  </a-spin>
</template>
<script>  
  import encryptStorage from "../../controllers/encryptStorage.js";
  import HTTPRequest from "../../controllers/httpRequest.js";
  import { mapState } from "vuex";
  import Autocomplete from 'vuejs-auto-complete';

  export default {
    name: "MassUpdateBookings",
    components: { Autocomplete },
    computed: {
      ...mapState(["endPoint"])
    },
    data() {
      return {
        errorMsgs: [],
        authHeaders: {
          Authorization: 'Bearer ' + encryptStorage.getItem("accessToken"),
          "Access-Control-Allow-Origin": "*" 
        },
        spinning: false,
        data: {},
        dateFormat: "DD-MMM-YYYY",
        filterStartDate: '',
        filterEndDate: '',
        selectedClients: [],
        selectedCourses: [],
      }
    },
    methods: {
        findClientsEndpoint(searchText) {
            return this.endPoint + "/api/v1/users/find_clients?name=" + searchText;
        },
        autocompleteFormatDisplayClient (result) {
            return result.name + ' ' + result.surname;
        },
        selectClient (group) {
            let exists = this.selectedClients.find(d => d.id == group.selectedObject.id);
            if (!exists) {
            this.selectedClients.push(group.selectedObject);
            }
            this.$refs.autocomplete1.clear();
        },
        removeClient(clien_id){
            let clients = this.selectedClients.filter(d => d.id !== clien_id);
            this.selectedClients = clients;
        },

        findCoursesEndpoint(searchText) {
            return this.endPoint + "/api/v1/bookings/find_courses?title=" + searchText;
        },
        autocompleteFormatDisplayCourse (result) {
            return result.title;
        },
        selectCourse (group) {
            let exists = this.selectedCourses.find(d => d.id == group.selectedObject.id);
            if (!exists) {
            this.selectedCourses.push(group.selectedObject);
            }
            this.$refs.autocomplete.clear();
        },
        removeCourse(course_id){
            let courses = this.selectedCourses.filter(d => d.id !== course_id);
            this.selectedCourses = courses;
        },
        async massUpdate(){
            let client_ids = this.selectedClients.map(a => a.id);
            let course_ids = this.selectedCourses.map(a => a.id);

            if (client_ids.length < 1 || course_ids.length < 1) {
                this.errorMsgs.push("Please select atleast one client and one course.")
                return;
            }

            var body = {client_ids: client_ids, course_ids: course_ids, data: this.data, start_date: this.filterStartDate, end_date: this.filterEndDate};
            var response = await HTTPRequest.postRequest(this.endPoint, "/api/v1/bookings/mass_update_bookings", null, body);
            if (response.status == true) {
            this.$emit('sendToBookings', response.client);
            } else {
            this.errorMsgs.push(response.status);
            }
        },
        dateChange(date, dateString) {
            this.filterStartDate = dateString[0];
            this.filterEndDate = dateString[1];
        }
    }
  };
</script>


<style scoped>

</style>